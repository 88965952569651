import * as util from '../util';
import { Rec, Today, User } from '../store';
import { Record } from '../record/record';
import * as moment from 'moment';
import { AuditSpecs } from '../audit-specs/audit-specs';

export class Audit {
  _id?: string;
  pick: string;
  date: string;
  orchard: string;
  kpin: string;
  maturityArea: string;
  startTime: string;
  auditor: string;
  auditTime: string;
  harvester: string;
  specs: AuditSpecs;

  static score(a: Audit): number {
    const catTotal = a.specs.categories
      .map(c => c.weight)
      .reduce((acc, val) => acc + val, 0);
    let total = 0;
    for (let i = 0; i < a.specs.categories.length; ++ i) {
      const catScore = Audit.catScore(a, i);
      total = total + (catScore * a.specs.categories[i].weight / catTotal);
    }
    return total;
  }

  static catScore(a: Audit, i: number): number {
    const specTotal = a.specs.categories[i].specs
      .map(s => s.weight)
      .reduce((acc, val) => acc + val, 0);
    
    let total = 0;
    for (let j = 0; j < a.specs.categories[i].specs.length; ++ j) {
      const s = (a.specs.categories[i].specs[j].pass as any);
      if (s === 1 || s === 0 || s === true) {
        const weight = a.specs.categories[i].specs[j].weight / specTotal;
        total = total + weight;
      }
    }
    return total;
  }

  static scoresAverage(a: Audit[]): number {
    if (a.length === 0) {
      return 0;
    } else {
      const scoreSum = a.map(aa => Audit.score(aa))
        .reduce((acc, val) => acc + val, 0);
      return scoreSum / a.length;
    }
  }

  static toCSV(a : Audit): string {
    const c = ', ';
    let s = a._id + c +
            a.kpin + c +
            a.orchard + c +
            a.maturityArea + c +
            a.date + c +
            a.auditor + c +
            a.harvester + c + 
            a.specs[2][0].pass + c +
            a.specs[2][1].pass + c +
            a.specs[2][2].pass + c +
            a.specs[2][3].pass + c +
            a.specs[2][4].pass + c +
            a.specs[3][0].pass + c;
    return s;
  }

  constructor(pick: Record, auditor: User, specs: AuditSpecs) {
    this.pick = pick._id.toString();
    this.date = pick.date;
    this.orchard = pick.orchard;
    this.kpin = pick.kpin;
    this.maturityArea = pick.maturityArea;
    this.startTime = pick.startTime;
    this.auditor = auditor.email;
    this.auditTime = moment().format('HH:mm:ss');
    this.harvester = pick.contractor;
    this.specs = specs;   
    // add a pass field to each spec
    for (let i = 0; i < specs.categories.length; ++ i) {
      for (let j = 0; j < specs.categories[i].specs.length; ++ j) {
        specs.categories[i].specs[j].pass = 0;
      }
    }
  }
}

export class Specification {
  spec: string;
  pass: number;
}

