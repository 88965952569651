import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { reRouteWithError } from "../util";
import { TransportService } from "./transport.service";

@Component({
  selector: '',
  templateUrl: './transport.component.html'
})
export class TransportComponent implements OnInit {

  companies: Company[] = []
  modified = {
    user: '',
    date: new Date()
  }

  constructor(
    private transportService: TransportService,
    private router: Router  
  ) {

  }

  ngOnInit(): void {
      this.transportService.getTransport().subscribe(
        data => {
          console.log(data)
          this.companies = data.companies.map(Company.display)
          this.modified.user = data.email
          this.modified.date = new Date(data.date)
        },
        error => {
          console.log(error);
        }
      )
  }

  addCompany(): void {
    this.companies.push(new Company('', true));
  }

  toggleCompany(c: Company): void {
    c.toggle();
  }

  submit(): void {
    let cs = this.companies.filter(c => c.status).map(c => c.name)
    this.transportService.setTransport(cs).subscribe(
      data => {
        this.companies = data.companies.map(Company.display)
        console.log('success')
      },
      error => reRouteWithError(this.router, error)
    )
  }

  disabled(c): boolean {
    return !c.status;   
  }
}



class Company {
  
  name: string
  status: boolean
  
  constructor(n: string, s: boolean) {
    this.name = n;
    this.status = s;
  }

  static display(c: string) {
    return new Company(c, true)
  }

  toggle() {
    this.status = !this.status;
  }
}