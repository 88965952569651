import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrchardsService } from '../orchards/orchards.service';
import { Record } from '../record/record';
import { Router } from '@angular/router';
import { Rec } from '../store';
import * as util from '../util';

@Component({
  selector: 'app-pick-list',
  templateUrl: './pick-list.component.html',
  styleUrls: ['./pick-list.component.css']
})
export class PickListComponent implements OnInit {

  kpin: string;
  picks: Record[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orchardsService: OrchardsService
  ) {
    this.route.params.subscribe(params => {
      this.kpin = params.kpin;
      this.orchardsService.getPicks(this.kpin).subscribe(
        data => {
          data.sort((a, b) => {
            if (a.date.localeCompare(b.date) === 1) {
              return -1;
            } else {
              return 1;
            }
          });
          this.picks = data;
        },
        error => util.reRouteWithError(this.router, error)
      );
    });
  }

  toOrchard(r: Record): void {
    Rec.store(r);
    this.router.navigate(['/pick/' + r._id]);
  }

  statusString(stat: string): string {
    return util.statusClass(stat, 'table');
  }

  ngOnInit() {
  }

}
