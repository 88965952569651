import { Component, OnInit } from '@angular/core';
import * as util from '../util';
import { Audit } from './audit';
import { Rec, Today, User } from '../store';
import { Record } from '../record/record';
import { AuditService } from './audit.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditSpecs } from '../audit-specs/audit-specs';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})
export class AuditComponent implements OnInit {

  // specs = util.specifications;
  specs: AuditSpecs;
  audit: Audit; 
  user: User;
  record: Record;
  sure = {
    submit: false,
    update: false,
    delete: false
  }
  old = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auditService: AuditService,
    public location: Location) {
      this.record = Rec.get();
      this.user = User.get();
      // get the latest audit specs from the server
      this.auditService.latestSpec().subscribe(
        data => {
          console.log(data);
          // we need to set the specs to the latest specs
          this.specs = data
          this.audit = new Audit(this.record, this.user, this.specs);
          this.route.params.subscribe(params => {
            if (params.aid) {
              this.auditService.getAudit(params.aid).subscribe(
                data => {
                  this.audit = data;
                  this.old = true;
                },
                error => util.reRouteWithError(this.router, error)
              );
            }
          });
        },
        error => {
          console.log(error);
        }
      );      
  }

  itemColor(i: number, j: number): string {
    if (this.audit) {
      const s = this.audit.specs.categories[i].specs[j].pass as any;
      switch (s) {
        case 0:
        case true:
          return 'alert alert-success';
        case 1:
          return 'alert alert-warning';
        case 2:
        case false:
          return 'alert alert-danger';
      }
    }
  }

  toggleItem(i: number, j: number): void {
    if (!this.old || util.managers.includes(User.get().role)) {
      let currentPass = this.audit.specs.categories[i].specs[j].pass as any;
      this.audit.specs.categories[i].specs[j].pass = (currentPass + 1) % 3;
    }
  }

  submit(): void {
    if (this.sure.submit) {
      const req = this.auditService.newAudit(this.audit);
      req.subscribe(
        data => {
          this.location.back();
        },
        error => util.reRouteWithError(this.router, error)
      );
    } else {
      this.sure.submit = true;
    }
  }

  delete(): void {
    if (this.sure.delete) {
      const req = this.auditService.deleteAudit(this.audit);
      req.subscribe(data => {
        this.router.navigate(['/pick/', this.record._id]);
        this.location.back();
      },
      error => {
        console.log(error);
        util.reRouteWithError(this.router, error)
      })
    } else {
      this.sure.delete = true;
    }
  }

  update(): void {
    if (this.sure.update) {
      this.audit.auditor = User.get().email;
      const req = this.auditService.updateAudit(this.audit);
      req.subscribe(data => {
        this.location.back();
      },
      error => {
        util.reRouteWithError(this.router, error)
      })
    } else {
      this.sure.update = true;
    }
  }

  submitText(action: string): string {
    let txt = action.toLowerCase();
    if (this.sure[txt]) {
      return 'Are you sure?';
    } else {
      return action + ' audit';
    }
  }

  submitClass(action: string): string {
    if (this.sure[action]) {
      return 'btn btn-warning';
    } else {
      return 'btn btn-success';
    }
  }

  catScore(i: number): string {
    let score = Audit.catScore(this.audit, i);
    score = score * 100;
    return score.toFixed(1) + '%';

  }

  score(): string {
    let score = Audit.score(this.audit);
    score = score * 100;
    return parseFloat(score.toFixed(1)) + '%';    
  }

  auditor() {
    return util.roles.slice(0, 4).includes(this.user.role);
  }

  ngOnInit() {
  }
}
