import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import * as util from '../util';
import { Record } from '../record/record';
import { RecordService } from '../record/record.service';
import { Rec, Today, User } from '../store';
import { AuditService } from '../audit/audit.service';

@Component({
  selector: 'app-pick',
  templateUrl: './pick.component.html',
  styleUrls: ['./pick.component.css']
})
export class PickComponent implements OnInit {

  record: Record;
  audits = 0;
  

  constructor(
    private recordService: RecordService,
    private auditService: AuditService,
    private router: Router,
    public location: Location
  ) {
    this.record = Rec.get();
    console.log(this.record)
    this.getAudits();
  }

  back() {
    const day = Today.get();
    this.router.navigate(['/records/' + day.date]);
  }

  pickingRate() {
    const rate = util.pickingRate(this.record);
    if (isNaN(rate)) {
      return '';
    } else {
      return rate.toFixed(1);
    }
  }

  pickingTime() {
    return util.pickingTime(this.record);
  }

  statusString(stat: string): string {
    return util.statusClass(stat, 'alert');
  }

  manager() {
    return util.managers.includes(User.get().role);
  }

  auditor() {
    return User.get().role === 'auditor' || User.get().role === 'self';
  }

  getData() {
    this.recordService.getRecord(this.record._id).subscribe(
      data => {
        this.record = data[0];
      },
      error => util.reRouteWithError(this.router, error)
    )
  }

  getMap() {
    const req = this.recordService.getMap(this.record);
    req.subscribe(
      data => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const bb = window.URL.createObjectURL(blob);
        const link = window.document.createElement('a');
        link.href = bb;
        link.download = this.record.kpin + '.pdf';
        document.body.appendChild(link);
        link.click();
        link.remove();
      }, 
      error => util.reRouteWithError(this.router, error)
    );
  }

  getAudits(): void {
    const req = this.auditService.getAudits(this.record);
    req.subscribe(
      data => {
        this.audits = data.length;
      }, 
      error => util.reRouteWithError(this.router, error)
    );
  }

  softFruit() {
    return this.record.softFruit ? '✓' : 'x';
  }

  ngOnInit() {
    this.record = Rec.get();
    this.getData();
  }

  onDestroy() {
  }
}
