import { Component, OnInit } from '@angular/core';
import { UsersService } from './users.service';
import * as util from '../util';
import { Router } from '@angular/router';
import { User } from '../store';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

    harvestManagement: User[] = [];
    auditors: User[] = [];
    selfPickers: User[] = [];
    contractors: User[] = [];
    transporters: User[] = [];

    constructor(
        private usersService: UsersService,
        private router: Router,
    ) { }

    toUser(user): void {
        if (user) {
            User.storeOther(user);
        } else {
            User.storeOther(new User());
        } 
        this.router.navigate(['/user']);
    }

    showManagers() {
        return util.managers.includes(User.get().role)
    }

    ngOnInit() {
        this.usersService.getUserList().subscribe(
            data => {
                this.harvestManagement = data.filter(
                    u => util.managers.includes(u.role)
                );
                this.auditors = data.filter(u => u.role === 'auditor');
                this.selfPickers = data.filter(u => u.role === 'self');
                this.contractors = data.filter(u => u.role === 'contractor');
                this.transporters = data.filter(u => u.role === 'transport');
                console.log(this.harvestManagement)
            },
            error => util.reRouteWithError(this.router, error)
        )
    }
}
