import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import * as parse from 'csv-parse';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Record } from './record/record';
import { RecordService } from './record/record.service';
import { User } from './store';
import { url } from './util';

@Injectable()
export class ImportService {

    constructor(
        private recordService: RecordService,
        private http: HttpClient) { }

    // importGoogleCSV(path) {
    //     let fr = new FileReader();
    //     fr.onload = loaded => {
    //         if (fr.readyState !== 2) throw "File load error";
    //         let text = fr.result;
    //         parse(text, (csvError, output) => {
    //             if (csvError) throw csvError;
    //             output = output.slice(1);
    //             let records: Record[] = [];
    //             for (let i = 0; i < output.length; ++i) {
    //                 let row = output[i];
    //                 let record = {
    //                     date: moment(row[0], 'DD/MM/YYYY').format('YYYY-MM-DD'),
    //                     pickPlan: row[1],
    //                     orchard: row[2],
    //                     variety: row[3],
    //                     kpin: row[4],
    //                     maturityArea: row[5],
    //                     blocks: row[6],
    //                     packhouse: row[7],
    //                     contractor: row[8],
    //                     transport: row[9],
    //                     binsEstimate: row[10],
    //                     comments: row[12],
    //                     startTime: row[13],
    //                     updateTime: row[14],
    //                     binsPicked: row[15],
    //                     status: row[16]
    //                 };
    //                 if (record.orchard !== '') records.push(<Record>record);
    //             }
    //             const chunkSize = 64;

    //             while (records.length > 100) {
    //                 const chunk = records.slice(0, chunkSize);
    //                 records = records.slice(chunkSize);
    //                 this.recordService.addRecords(chunk).subscribe(
    //                     data => console.log(data),
    //                     error => console.log(error)
    //                 );
    //             }
    //         });
    //     };
    //     fr.readAsText(path);
    // }

}
