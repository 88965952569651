import { Component, OnInit } from '@angular/core';
import { Bin, BinTrans } from '../bins-dashboard/bins-dashboard.component';
import { BinsAllocations, Today } from '../store';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { BinsService } from '../bins-dashboard/bins.service';
import * as util from '../util';

@Component({
  selector: 'app-bins',
  templateUrl: './bins.component.html',
  styleUrls: ['./bins.component.css']
})
export class BinsComponent implements OnInit {


  transaction: BinTrans = {
    date : Today.get().date,
    time : moment().toISOString(),
    amount : 0,
    from : '',
    to : ''
  }

  sources: string[] = []
  bins: Bin[] = [];

  errorText: string[] = [];

  constructor(
    private router: Router,
    private binsService : BinsService
    ) {
    this.bins = BinsAllocations.get();
    this.sources = this.bins.map(b => b.kpin);
  }
  

  ngOnInit() {
  }

  addTransaction() {
    if (this.bins.length === 0) {
      this.transaction.from = 'storage';
      this.transaction.to = 'site';
    }
    if (this.checkTransaction()) {
      this.binsService.addBin(this.transaction).subscribe(
        data => {
          this.back();
        },
          error => util.reRouteWithError(this.router, error)
      );
    }    
  }

  checkTransaction(): boolean {
    this.errorText = [];
    let correct = true;
    if (this.transaction.to === '') {
      this.errorText.push('You need a destination to transfer bins to.')
      correct = false;
    }
    if (this.transaction.from === '') {
      this.errorText.push('The bins need to come from somewhere.')
      correct = false;
    }
    // check from and to are different
    if (this.transaction.from === this.transaction.to ) {
      this.errorText.push('From and to should be different.')
      correct = false;
    }
    // check there are bins being transfered
    if (this.transaction.amount === 0) {
      this.errorText.push('A transaction should assign more than zero bins.');
      correct = false;
    }
    // check from has enough, storage is an infinite source
    
    if (this.transaction.from !== 'storage' && this.transaction.from !== '') {
      if (this.transaction.amount > this.available()) {
        this.errorText.push(
          'There is not enough bins available at ' 
          + this.transaction.from 
          + ', max is ' + this.available() + '.');
        correct = false;
      }
    } 
    return correct;
  }

  available() {
    let a = 0;
    this.bins.forEach(b => {
      if (b.kpin === this.transaction.from) {
        a = b.amount
      }
    })
    return a;
  }

  infoText(): string {
    return this.transaction.from + ' has ' + this.available() + ' bins available.'
  }

  back() {
    this.router.navigate(['/bins/' + Today.get().date])
  }

  niceDate() {
    return moment(Today.get().date).format('dddd Do MMMM');
  }

}
