import { Component } from '@angular/core';
import { ImportService } from './import.service';
import { Today, User, Rec } from './store';
import { Record } from './record/record';
import { Router } from '@angular/router';
import * as util from './util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {


    constructor(
        private router: Router,
        private importService: ImportService
    ) {

    }

    // Function to all access of the current day string from HTML context
    date(): string {
        return Today.get().date;
    }

    // To control items viewable only by managers
    manager() {
        return util.managers.includes(User.get().role);
    }

    // To control elements viewable by
    // logged in users
    loggedIn() {
        return User.get().role !== '';
    }

    create() {
        Rec.store(new Record());
        this.router.navigate(['/records/new'])
    }

    import(file) {
        // this.importService.importGoogleCSV(file);
    }

    // Remove the current user from the state
    // and send to login page
    logout() {
        User.store(undefined);
        this.router.navigate(['/login']);
    }

}
