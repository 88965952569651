import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UsersService } from '../users/users.service';
import { User } from '../store';
import * as util from '../util';
import { Router } from '@angular/router';
import { TransportService } from '../transport/transport.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  user: User = User.getOther();
  hasPassword: boolean;
  transporters = [];
  roles: string[] = util.roles;
  reset = false;
  delete = false;

  constructor(
    public location: Location,
    private usersService: UsersService,
    private transportersService: TransportService,
    private router: Router
  ) {
    this.transportersService.getTransport().subscribe(
      data => {
        console.log(data.companies)
        this.transporters = data.companies;
      },
      error => util.reRouteWithError(this.router, error)
    )
    this.hasPassword = this.user.password;
  }

  requiresCompany(role: string) {
    return role === 'transport';
  }

  save() {
    // is an add or update
    if (this.user.hasOwnProperty('_id')) {
      this.usersService.update(this.user).subscribe(
        data => this.location.back(),
        error => util.reRouteWithError(this.router, error)
      )
    } else {
      this.usersService.create(this.user).subscribe(
        data => this.location.back(),
        error => util.reRouteWithError(this.router, error)
      )
    }
  }

  deleteUser(n: number) {
    if (n === 0) {
      this.delete = true;
    } else {
      this.usersService.delete(this.user).subscribe(
        data => this.location.back(),
        error => util.reRouteWithError(this.router, error)
      )
    }
    
  }

  resetPassword(n: number) {
    if (n === 0) {
      this.reset = true;
    } else {
      this.usersService.resetPassword(this.user).subscribe(
        data => this.location.back(),
        error => util.reRouteWithError(this.router, error)
      );
    }
  }

  ngOnInit() {
  }

}


