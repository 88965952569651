import { Component, OnInit, OnDestroy } from '@angular/core';
import * as util from '../util';
import * as moment from 'moment';
import { Observable, of, from } from 'rxjs';
import { Record } from '../record/record';
import { RecordService } from '../record/record.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Today, Rec, Pack, User } from '../store';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  packhouses: string[] = util.packhouse;
  varieties: string[] = util.varieties;
  day: Today;

  totals: string[] = util.totals;
  currentReq: any;

  currentDay: String = moment().format('DDDo');

  records: Record[] = [];
  display = {
    'Te Puke': {
      'Te Puke': [],
      'Te Puna': []
    },
    'Te Puna': {
      'Te Puke': [],
      'Te Puna': []
    }
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private recordService: RecordService) {
    this.route.params.subscribe(params => {
      Today.store(<Today>params);
      this.day = Today.get();
      if (this.currentReq) {
        clearInterval(this.currentReq);
      }
      this.getData();
    });
  }

  displayDate(date: string) {
    return moment(date, 'YYYY-MM-DD').format('Do MMM YY');
  }

  changeDay(): void {
    if (this.day.date !== '') {
      Today.store(this.day);
      clearInterval(this.currentReq);
      this.router.navigate(['/dashboard/' + this.day.date]);
    }
  }

  toOrchard(r: Record): void {
    Rec.store(r);
    this.router.navigate(['/pick/' + r._id]);
  }

  displayTime(time: string): string {
    const t = moment(time, 'HH:mm:SS');
    if (t.isValid()) {
      return t.format('h:mm a');
    } else {
      return '';
    }
  }

  niceDate() {
    return moment(this.day.date).format('dddd Do MMMM');
  }

  addMA() {
    Rec.store(new Record());
    this.router.navigate(['/records/new']);
  }

  statusString(stat: string): string {
    return util.statusClass(stat, 'table');
  }

  pickTotal(packhouse: string, kind: string): number {
    return this.records.filter(
      rec => rec.packhouse === packhouse
        && rec[kind] !== ''
        && rec[kind] !== 'TBC'
    ).map(
      rec => parseInt(rec[kind], 10)
    ).reduce(
      (tot, bins) => tot += bins, 0
    );
  }

  varietyTotals(ph, va, kind) {
    return this.records.filter(
      rec => rec.packhouse === ph
        && rec.variety === va
        && rec[kind] !== ''
        && rec[kind] !== 'TBC'
    ).map(
      rec => parseInt(rec[kind], 10)
    ).reduce(
      (tot, bins) => tot += bins, 0
    );
  }

  getData() {
    const request = () => {
      const now = moment().format('DDDo');
      if (now !== this.currentDay) {
        this.day = new Today();
      }
      this.currentDay = now;
      this.recordService.getRecordsList(this.day.date).subscribe(
        data => {
          this.records = data;
          data.sort(util.statusSort);
          // make the data object for display
          const ke = data.filter(rec => rec.pickPlan === 'Te Puke');
          const na = data.filter(rec => rec.pickPlan === 'Te Puna');
          this.display = {
            'Te Puna': {
              'Te Puna': na.filter(rec => rec.packhouse === 'Te Puna'),
              'Te Puke': na.filter(rec => rec.packhouse === 'Te Puke')
            },
            'Te Puke': {
              'Te Puna': ke.filter(rec => rec.packhouse === 'Te Puna'),
              'Te Puke': ke.filter(rec => rec.packhouse === 'Te Puke')
            }
          };
        },
        error => util.reRouteWithError(this.router, error)
      );
    };
    request();
    this.currentReq = setInterval(request, 10000);
  }

  manager() {
    return util.managers.includes(User.get().role);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    clearInterval(this.currentReq);
  }
}
