import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as util from '../util';
import * as moment from 'moment';
import { User, Today } from '../store';
import { UsersService } from '../users/users.service'


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  season: string = moment().format('YY');
  user: User = User.get();
  attempts = 0;
  status = 'hello'

  password = ''

  pass1 = ''
  pass2 = ''

  passwordWarnings: string[]

  crypto: SubtleCrypto


  constructor(
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.crypto = window.crypto.subtle;
    this.route.params.subscribe(ps => this.status = ps.status);
  }

  ngOnInit() {
    
    this.passwordWarnings = [];
    if (this.user.token === undefined) {
      this.user.token = '';
    }
  }

  // Log in the given user, need to store the user given back from server
  // as this contains our role.
  async login() {

    if (this.status === 'password' || this.status === 'badPassword') {
      const hash = await util.hashPassword(this.password);
      console.log(hash)
      this.usersService.passwordLogin(this.user, hash).subscribe(
        data => {
          console.log(data)
          this.user = data;
          User.store(this.user);
          this.getToken();
          this.rerouteAfterLogin();
        },
        error => util.reRouteWithError(this.router, error)        
      )
    } else {
      if (this.attempts < 15) {
        this.usersService.tokenLogin(this.user).subscribe(
          data => {
            this.user = data;
            console.log(data)
            User.store(data);
            this.getToken();
            this.rerouteAfterLogin();
          },
          error => util.reRouteWithError(this.router, error)
        );
      } else if (this.attempts === 15) {
        window.setTimeout(() => this.attempts = 0, 30 * 1000);
      }
    }



  }

  // get the token for dropbox
  getToken() {
    this.usersService.getToken(this.user).subscribe(
      data => {
        console.log(data);
        localStorage.dropbox_token = JSON.stringify(data);
      },
      error => util.reRouteWithError(this.router, error)
    );
  }

  passwordStatus(): string {
    let status = 'btn-success'
    let s = new Set<string>()
    if (this.pass1.length < 4) {
      status = 'btn-danger disabled'
      s.add('Try making a longer password.')
    }
    if (this.pass1 !== this.pass2) {
      status = 'btn-danger disabled'
      s.add('Try making the passwords match.')
    }
    this.passwordWarnings = Array.from(s)
    return status
  }

  submitPassword() {

    console.log(this.user)
    if (this.passwordWarnings.length === 0) {
      console.log('ok password');
      util.hashPassword(this.pass1).then(hash => {
        console.log(hash)
        this.usersService.newPassword(this.user, hash).subscribe(
          data => {
            //console.log(data)
            this.user.token = data.token;
            this.user.role = data.role;
            //console.log(this.user)
            User.store(this.user);
            this.rerouteAfterLogin()
          },
          error => util.reRouteWithError(this.router, error)
        );
      });
    } else {
      console.log('bad password')
    }
  }

  rerouteAfterLogin() {
    if (util.managers.includes(this.user.role)) {
      this.router.navigate(['/dashboard/' + new Today().date]);
    } else {
      this.router.navigate(['/records/' + new Today().date]);
    }
  }
}
