import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { RecordListComponent } from './record-list/record-list.component';
import { LoginComponent } from './login/login.component';
import { RecordComponent } from './record/record.component';
import { ImportService } from './import.service';
import { RecordService } from './record/record.service';
import { UsersComponent } from './users/users.component';
import { UsersService } from './users/users.service';
import { UserComponent } from './user/user.component';
import { StopwatchComponent } from './stopwatch/stopwatch.component';
import { PickComponent } from './pick/pick.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SetupComponent } from './setup/setup.component';
import { AssignUsersComponent } from './assign-users/assign-users.component';
import { AuditComponent } from './audit/audit.component';
import { AuditListComponent } from './audit-list/audit-list.component';
import { AuditService } from './audit/audit.service';
import { AuditReportsComponent } from './audit-reports/audit-reports.component';
import { ChartsModule } from 'ng2-charts';
import { OrchardsComponent } from './orchards/orchards.component';
import { OrchardsService } from './orchards/orchards.service';
import { PickListComponent } from './pick-list/pick-list.component';
import { BinsDashboardComponent } from './bins-dashboard/bins-dashboard.component';
import { BinsComponent } from './bins/bins.component';
import { BinsSearchComponent } from './bins-search/bins-search.component';
import { TransportComponent } from './transport/transport.component';
import { TransportService } from './transport/transport.service';
import { PasswordComponent } from './password/password.component';
import { AuditSpecComponent } from './audit-specs/audit-spec.component';

const routes: Routes = [
    {
        path: 'login/:status',
        component: LoginComponent
    },
    {
        path: 'records/new',
        component: RecordComponent
    },
    {
        path: 'records/:date',
        component: RecordListComponent
    },
    {
        path: 'records/:date/:action/:area',
        component: RecordListComponent
    },
    {
        path: 'dashboard/:date',
        component: DashboardComponent
    },
    {
        path: 'record/:id',
        component: RecordComponent
    },
    {
        path: 'record/:id/assign',
        component: AssignUsersComponent
    },
    {
        path: 'stopwatch/:id',
        component: StopwatchComponent
    },
    {
        path: 'orchards',
        component: OrchardsComponent
    },
    {
        path: 'orchards/:kpin',
        component: PickListComponent
    },
    {
        path: 'pick/:id',
        component: PickComponent
    },
    {
        path: 'pick/:id/audit',
        component: AuditComponent
    },
    {
        path: 'pick/:id/audit/:aid',
        component: AuditComponent
    },
    {
       path: 'audits/reports',
       component: AuditReportsComponent
    },
    {
        path: 'audits/specifications',
        component: AuditSpecComponent
    },
    {
        path: 'pick/:id/audits',
        component: AuditListComponent
    },
    {
        path: 'setup',
        component: SetupComponent
    },
    {
        path: 'users',
        component: UsersComponent
    },
    {
        path: 'user',
        component: UserComponent
    },
    {
        path: 'bins/search',
        component: BinsSearchComponent
    },
    {
        path: 'bins/:date',
        component: BinsDashboardComponent
    },
    {
        path: 'bins/:date/add',
        component: BinsComponent
    }, 
    {
        path: 'transporters',
        component: TransportComponent
    },
    {
        path: 'thing',
        component: PasswordComponent
    },  
    {
        path: '**',
        redirectTo: '/login/hello',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [
        AppComponent,
        RecordListComponent,
        LoginComponent,
        RecordComponent,
        UsersComponent,
        UserComponent,
        StopwatchComponent,
        PickComponent,
        DashboardComponent,
        SetupComponent,
        AssignUsersComponent,
        AuditComponent,
        AuditListComponent,
        AuditReportsComponent,
        AuditSpecComponent,
        OrchardsComponent,
        PickListComponent,
        BinsDashboardComponent,
        BinsComponent,
        BinsSearchComponent,
        TransportComponent,
        PasswordComponent
    ],
    imports: [
        RouterModule.forRoot(routes),
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ChartsModule
    ],
    providers: [
        RecordService,
        UsersService,
        ImportService,
        AuditService,
        OrchardsService,
        TransportService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
