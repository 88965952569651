import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Record } from '../record/record';
import { User } from '../store';
import { url } from '../util';
import { Orchard } from './orchards.component';


@Injectable()
export class OrchardsService {

  constructor(private http: HttpClient) { }

  getOrchards(): Observable<Orchard[]> {
    const route = url + '/orchards/all';
    const body = {
      user: User.get()
    };
    return this.http.post<Orchard[]>(route, body);
  }

  getPicks(kpin: string): Observable<Record[]> {
    const route = url + '/orchards/picks';
    const body = {
      user: User.get(),
      kpin: kpin
    };
    return this.http.post<Record[]>(route, body);
  }
}
