import { Record } from './record/record';
import * as moment from 'moment';
import { Orchard } from './orchards/orchards.component';
import { Bin, BinTrans } from './bins-dashboard/bins-dashboard.component';
// This is the current user of the application

export class User {

    _id?: string;
    email = '';
    role = '';
    company?: string;
    packhouse?: string;
    orchards?: string[];
    token = '';
    password?: boolean;

    static get(): User {
        let u: User;
        try {
            u = JSON.parse(localStorage.user);
        } catch (e) {
            u = new User();
        }
        return u;
    }

    static store(u: User) {
        localStorage.user = JSON.stringify(u);
    }

    static getOther(): User {
        let u: User;
        try {
            u = JSON.parse(localStorage.otherUser);
        } catch (e) {
            u = new User();
        }
        return u;
    }

    static storeOther(u: User) {
        localStorage.otherUser = JSON.stringify(u);
    }
}

// export class AuthToken {
//     token: string = '';

//     constructor(data: string) {
//         this.token = data;
//     }

//     static get(): AuthToken {
//         let t: AuthToken;
//         try {
//             t = JSON.parse(localStorage.authToken);
//         } catch(e) {
//             t = new AuthToken('')
//         }
//         return t
//     }

//     static store(t: AuthToken) {
//         localStorage.authToken = JSON.stringify(t);
//     }
// }

// This is the pointer to the day the user
// is currently looking at

export class Today {

    date: string = moment().format('Y-MM-DD');

    static get(): Today {
        let d: Today;
        try {
            d = JSON.parse(localStorage.today);
        } catch (e) {
            d = new Today();
        }
        return d;
    }
    static store(t: Today) {
        localStorage.today = JSON.stringify(t);
    }
}

export class Rec {

    record: Record = new Record();

    static get(): Record {
        let r: Record;
        try {
            r = JSON.parse(localStorage.record);
        } catch (e) {
            console.log(e);
            r = new Record();
        }
        return r;
    }

    static store(r: Record) {
        localStorage.record = JSON.stringify(r);
    }
}

export class Pack {
    packhouse = 'Te Puke';
    static get(): string {
        let p: Pack;
        try {
            p = JSON.parse(localStorage.packhouse);
        } catch (e) {
            p = new Pack();
        }
        return p.packhouse;
    }

    static store(ph: string): void {
        localStorage.packhouse = ph;
    }
}

export class Bins {

    static get() {
        let counts;
        try {
            counts = JSON.parse(localStorage.bins);
        } catch (e) {
            counts = [];
        }
        return counts;
    }

    static store(bins): void {
        localStorage.bins = bins;
    }
}

export class BinsAllocations {

    static get(): Bin[]  {
        let bins;
        try {
            bins = JSON.parse(localStorage.binsAllocations);
        } catch (e) {
            bins = [];
        }
        return bins;
    }

    static store(bins: Bin[]): void {
        localStorage.binsAllocations = JSON.stringify(bins);
    }
}

export class TrieNode {

    value: Orchard;
    key: string;
    children = new Map();


    constructor(k: string, v: Orchard) {
        this.key = k;
        this.value = v;
    }

    static find(node: TrieNode, key: string): TrieNode {
        let pointer = node;
        const chars = Array.from(key);
        chars.forEach(c => {
            if (pointer.children.has(c)) {
                pointer = pointer.children.get(c);
            } else {
                return null;
            }
        });
        return pointer;
    }

    static insert(node: TrieNode, orchard: Orchard): void {
        let pointer = node;
        const chars = Array.from(orchard.kpin);
        let prefix = '';
        chars.forEach(c => {
            prefix = prefix + c;
            if (!pointer.children.has(c)) {
                pointer.children.set(c, new TrieNode(prefix, null));
            }
            pointer = pointer.children.get(c);
        });
        pointer.value = orchard;
    }

    static values(node: TrieNode): Orchard[] {
        const vs = [];
        const stack: TrieNode[] = [];

        stack.push(node);
        while (stack.length > 0) {
            const pointer = stack.pop();
            // console.log(pointer);
            // visit
            if (pointer.value) {
                vs.push(pointer.value);
            }
            pointer.children.forEach(child => {
                stack.push(child);
            });
        }
        return vs;
    }
}
