import { Component, OnInit } from '@angular/core';
import { Audit } from '../audit/audit';
import { Record } from '../record/record';
import { Rec, Today, User } from '../store';
import * as util from '../util';
import { AuditService } from '../audit/audit.service';
import { Router } from '@angular/router';
import { AuditSpecs } from '../audit-specs/audit-specs';

@Component({
  selector: 'app-audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.css']
})
export class AuditListComponent implements OnInit {

  specs: AuditSpecs;
  audits: Audit[];
  user: User;
  record: Record;

  constructor(
    private auditService: AuditService,
    private router: Router
  ) {
    this.record = Rec.get();
    const rec = this.auditService.getAudits(this.record);
    rec.subscribe(data => {
      this.audits = data;
      // assume that all audits are done with the same spec as the first one
      if (this.audits.length === 0) {
        return;
      }
      const first = this.audits[0];
      this.specs = first.specs;
    },
    error => {
      util.reRouteWithError(this.router, error)
    });
  }

  shorten(st: string): string {
    const w = 32;
    if (st.length > w) {
      return st.substring(0, w) + '..';
    } else {
      return st;
    }
  }

  shortID(st: string): string {
    const len = st.length;
    return '..' + st.substring(len - 4, len);
  }

  cellColour(a: Audit, i: number, j: number) {
    const s = a.specs.categories[i].specs[j].pass as any;
    switch (s) {
      case 0:
      case true:
        return 'bg-success';
      case 1:
        return 'bg-warning';
      case 2:
      case false:
        return 'bg-danger';
    }
  }

  auditScore(audit: Audit): number {
    return Audit.score(audit);
  }

  averageCatScore(i: number) {
    if (this.audits) {
      const sum = this.audits.map(a => {
        return Audit.catScore(a, i);
      }).reduce((acc, val) => {
        return acc + val;
      }, 0);
      const avg = sum / this.audits.length;
      return (avg * 100).toFixed(2);
    } else {
      return 0;
    }
  }

  averageScore() {
    if (this.audits) {
      const sum = this.audits.map(a => {
        return Audit.score(a);
      }).reduce((acc, val) => {
        return acc + val;
      }, 0);
      const avg = sum / this.audits.length;
      return (avg * 100).toFixed(2);
    } else {
      return 0;
    }
  }

  auditor() {
    return util.roles.slice(0, 4).includes(User.get().role);
  }

  ngOnInit() {
  }

}
