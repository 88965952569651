import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as util from '../util';
import { User, Today } from '../store';
import { UsersService } from '../users/users.service'


@Component({
  selector: 'app-login',
  templateUrl: './password.component.html',
  
})
export class PasswordComponent implements OnInit {

  user: User = User.get();
  

  oldPassword = ''

  pass1 = ''
  pass2 = ''

  passwordWarnings: string[]

  crypto: SubtleCrypto


  constructor(
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.crypto = window.crypto.subtle;
  }

  ngOnInit() {
    
    this.passwordWarnings = [];
    if (this.user.token === undefined) {
      this.user.token = '';
    }
  }

  
  passwordStatus(): string {
    let status = 'btn-success'
    let s = new Set<string>()
    if (this.pass1.length < 4) {
      status = 'btn-danger disabled'
      s.add('Try making a longer password.')
    }
    if (this.pass1 !== this.pass2) {
      status = 'btn-danger disabled'
      s.add('Try making the passwords match.')
    }
    this.passwordWarnings = Array.from(s)
    return status
  }

  submitPassword() {
    if (this.passwordWarnings.length === 0) {
      console.log('ok password');
      util.hashPassword(this.pass1).then(hash => {
        console.log(hash)
        this.usersService.newPassword(this.user, hash).subscribe(
          data => {
            //console.log(data)
            this.user.token = data.token;
            this.user.role = data.role;
            //console.log(this.user)
            User.store(this.user);
            this.rerouteAfterLogin()
          },
          error => util.reRouteWithError(this.router, error)
        );
      });
    } else {
      console.log('bad password')
    }
  }

  rerouteAfterLogin() {
    if (util.managers.includes(this.user.role)) {
      this.router.navigate(['/dashboard/' + new Today().date]);
    } else {
      this.router.navigate(['/records/' + new Today().date]);
    }
  }
}
