import { Component, OnInit } from '@angular/core';
import { Record } from '../record/record';
import { Rec } from '../store';
import { ImportService } from '../import.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {

  constructor(
      private router: Router,
      private importService: ImportService
  ) { }

  create() {
      Rec.store(new Record());
      this.router.navigate(['/records/new'])
  }


  import(file) {
      // this.importService.importGoogleCSV(file);
  }


  ngOnInit() {
  }

}
