import { Component, OnInit, ViewChild } from '@angular/core';
import { AuditService } from '../audit/audit.service';
import { UsersService } from '../users/users.service';
import { User, Rec } from '../store';
import { Audit } from '../audit/audit';
import * as moment from 'moment';
import * as util from '../util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-audit-reports',
  templateUrl: './audit-reports.component.html',
  styleUrls: ['./audit-reports.component.css']
})
export class AuditReportsComponent implements OnInit {

  

  contractors: User[];
  year = new Date().getFullYear().toString();
  week: number;
  allAudits: Audit[];

  constructor(
    private auditService: AuditService,
    private usersService: UsersService,
    private router: Router
  ) {
    
  }

  getData() {
    this.usersService.getUserList().subscribe(
      // get all the users
      uData => {
        // we only need contractors
        this.contractors = uData.filter(u => {
          return u.role === 'contractor' && u.email !== '@self';
        });
        this.allAudits = [];
        // do a request for each contractor :(
        for (let i = 0; i < this.contractors.length; ++ i) {
          const cont = this.contractors[i].email;
          const bared = ['@garcia', '@parram', '@montrose'];
          if (bared.includes(cont, 0)) {
            continue;  
          }
          this.auditService.getHarvesterAudits(this.year, cont).subscribe(
            aData => {
              if (aData.length > 0) {
                this.allAudits = this.allAudits.concat(aData);
                const weeks = this.weekify(aData);
                const weeksScore = [];
                weeks.forEach(week => weeksScore.push(Audit.scoresAverage(week)));
                const data = {
                  data: weeksScore,
                  label: cont
                };
              }
            },
            error => {
              util.reRouteWithError(this.router, error);
            }
          );
        }
      },
      error => {
        util.reRouteWithError(this.router, error);
      }
    );
  }

  weekify(audits: Audit[]) {
    const weeks = [];
    for (let i = 12; i <= this.week; ++ i) {
      weeks[i] = [];
    }
    audits.forEach(a => {
      const w = moment(a.date).format('W');
      if (weeks[w]) {
        weeks[w].push(a);
      }      
    });
    return weeks;
  }

  downloadAudits() {
    if (util.managers.includes(User.get().role)) {
      //console.log(this.allAudits);
      // make header row
      let csv = 'ID, ' +
                'KPIN, ' +
                'Orchard, ' +
                'Maturity Area, ' +
                'Date, ' +
                'Auditor, ' +
                'Contractor, ' +
                'No raining observed, ' +
                'No poor delivery, ' +
                'No unnecessary dropped fruit, ' +
                'No missing fruit, ' +
                'No stalks in bin, ' +
                'One supervisor present per 3 team at all times\n';
      this.allAudits.forEach(audit => {
        csv = csv + Audit.toCSV(audit) + '\n';
      });
      console.log(csv);
      //const b = new Blob([JSON.stringify(this.allAudits)]);
      const b = new Blob([csv]);
      const bb = window.URL.createObjectURL(b);
      const link = window.document.createElement('a');
      link.href = bb;
      link.download = 'Picking Quality' + this.year + '.csv';
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    
  }

  changeYear(i: number) {
    this.year = '' + (parseInt(this.year, 10) + i);
    this.getData();
  }

  ngOnInit() {
    this.getData();
  }

}
