import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Record } from '../record/record';
import { User, Rec } from '../store';
import { UsersService } from '../users/users.service';
import { RecordService } from '../record/record.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as util from '../util';
import * as store from '../store';



@Component({
    selector: 'app-assign-users',
    templateUrl: './assign-users.component.html',
    styleUrls: ['./assign-users.component.css']
})
export class AssignUsersComponent implements OnInit {

    record: Record;
    auditors: User[];
    selfPickers: User[];
    contractors: User[];
    state = {};

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private usersService: UsersService,
        private recordService: RecordService,
        public location: Location
    ) { }

    getUsers() {
        this.usersService.getUserList().subscribe(
            data => {
                this.auditors = data.filter(user => user.role === 'auditor');
                this.selfPickers = data.filter(user => user.role === 'self');
                this.contractors = data.filter(user => user.role === 'contractor');
                this.auditors.forEach(user => this.state[user._id] = false);
                this.selfPickers.forEach(user => this.state[user._id] = false);
                this.contractors.forEach(user => this.state[user._id] = false);

                if (this.record.users) {
                    // select all the names attached to the record
                    this.record.users.forEach(id => {
                        this.state[id] = true;
                    });
                }
            },
            error => util.reRouteWithError(this.router, error)
        );
    }

    getData() {
        this.route.params.subscribe(params => {
            this.recordService.getRecord(params.id).subscribe(
                data => {
                    this.record = data[0];
                    this.getUsers();
                },
                error => {
                    console.log(error);
                }
            );
        });
    }

    isSelected(id) {
        if (this.state[id]) {
            return 'list-group-item-success';
        } else {
            return '';
        }
    }

    save() {
        const allowed = Object.keys(this.state).filter(key => this.state[key]);
        this.record.users = allowed;
        this.record.contractor = this.harvester();
        this.recordService.updateRecord(this.record).subscribe(
            data => {
                Rec.store(this.record);
                this.router.navigate(['/dashboard/' + store.Today.get().date]);
            },
            error => util.reRouteWithError(this.router, error)
        );
    }

    toggle(user: User) {
        const id = user._id;
        if (this.state[id]) { // is toggled on can always toggle off
            this.state[id] = false;
        } else {
            // adding user. can always add an auditor
            if (user.role === 'auditor' || !this.hasHarvester()) {
                this.state[id] = true;
            }
        }
    }

    hasHarvester() {
        return this.contractors.filter(u => this.state[u._id]).length !== 0
            || this.selfPickers.filter(u => this.state[u._id]).length !== 0;
    }

    harvester() {
        const harvesters = this.contractors.filter(u => this.state[u._id]);
        if (harvesters.length > 0) {
            return harvesters[0].email;
        } else {
            return '';
        }
    }

    toggleAll(flag) {
        Object.keys(this.state).forEach(id => this.state[id] = flag);
    }

    ngOnInit() {
        this.record = Rec.get();
        this.getData();
    }
}
