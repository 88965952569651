import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import * as util from '../util';
import { Record } from './record';
import { RecordService } from './record.service';
import { UsersService } from '../users/users.service';
import { Rec, Today, User } from '../store';
import { TransportService } from '../transport/transport.service';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.css']
})

export class RecordComponent implements OnInit {

  status: string[] = util.status;
  packhouse: string[] = util.packhouse;
  varieties: string[] = util.varieties;
  transporters: string[] = [];
  contractors: string[] = util.contractors;
  record: Record;
  shadow = {
    orchard: true,
    pickPlan: true,
    variety: true,
    kpin: true,
    maturityArea: true,
    blocks: true,
    packhouse: true,
    contractor: true,
    transport: true,
    binsEstimate: true,
    comments: true,
    startTime: true,
    updateTime: true,
    binsPicked: true,
    status: true
  };

  today: string = Today.get().date;
  users: User[];
  harvester: User;
  auditors: User[];
  currentPackhouse: string;

  constructor(
    private recordService: RecordService,
    private usersService: UsersService,
    private router: Router,
    private transportService: TransportService,
    public location: Location
  ) {
    this.record = Rec.get();
    if (this.record.date === '') {
      this.record.date = Today.get().date;
    }
    this.currentPackhouse = this.record.packhouse;
    this.getData();
    
  }

  save() {
    // check for change of packhouse
    if (this.currentPackhouse !== this.record.packhouse) {
      alert('packhouse changed!');
      this.currentPackhouse = this.record.packhouse;
    }
    Rec.store(this.record);
    this.recordService.updateRecord(this.record).subscribe(
      data => this.location.back(),
      error => util.reRouteWithError(this.router, error)
    );

  }

  create() {
    this.recordService.addRecords([this.record]).subscribe(
      data => {
        this.record = data[0];
        Rec.store(this.record);
        this.router.navigate(['/record/' + this.record._id + '/assign']);
      },
      error => util.reRouteWithError(this.router, error)
    );
  }

  deleteMA() {
    this.recordService.deleteRecord(this.record).subscribe(
      () => this.location.back(),
      error => util.reRouteWithError(this.router, error)
    );
  }

  getUsers() {
    if (this.record.users) {
      this.usersService.getRecordsUsers(this.record.users).subscribe(
        data => {
          this.users = data;
          this.auditors = this.users.filter(uu => uu.role === 'auditor');
          this.harvester = this.users.filter(uu => {
            return uu.role === 'self' || uu.role === 'contractor';
          })[0];
        },
        error => util.reRouteWithError(this.router, error)
      );
    }
  }

  getData() {
    if (this.record._id) {
      this.recordService.getRecord(this.record._id).subscribe(
          data => {
            const temp = data[0];
            Object.keys(this.shadow).forEach(prop => {
              if (this.shadow[prop]) {
                this.record[prop] = temp[prop];
              }
            });
            this.getUsers();
          },
          error => util.reRouteWithError(this.router, error)
        );
      
      
    }
    
    this.transportService.getTransport().subscribe(
      data => {
        this.transporters = data.companies
      },
      error => util.reRouteWithError(this.router, error)      
    )
  }

  changed(prop) {
    this.shadow[prop] = false;
  }

  ngOnInit() {

  }

  onDestroy() {
    
  }
}
