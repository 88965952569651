import { Component, OnInit } from '@angular/core';
import { OrchardsService } from './orchards.service';
import { TrieNode } from '../store';
import { Router } from '@angular/router';
import { reRouteWithError } from '../util';

@Component({
  selector: 'app-orchards',
  templateUrl: './orchards.component.html',
  styleUrls: ['./orchards.component.css']
})
export class OrchardsComponent implements OnInit {

  searchString = '';
  orchards: TrieNode;
  results: Orchard[];

  constructor(
    private orchardsService: OrchardsService,
    private router: Router
  ) {
    this.orchardsService.getOrchards().subscribe(
      data => {
        // make trie
        this.orchards = new TrieNode('', null);
        data.forEach(datum => {
          TrieNode.insert(this.orchards, datum);
        });
      },
      error => reRouteWithError(this.router, error)
    );
  }

  search() {
    if (this.searchString === '') {
      this.results = [];
    } else {
      const prefix = TrieNode.find(this.orchards, this.searchString);
      this.results = TrieNode.values(prefix);
    }
  }

  ngOnInit() {
  }

  toOrchard(r: Orchard): void {
    this.router.navigate(['/orchards/' + r.kpin]);
  }

}

export class Orchard {
  kpin: string;
  name: string;
}
