import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../store';
import { report, url } from '../util';

@Injectable()
export class TransportService {

  constructor(private http: HttpClient) { }

  getTransport() {
    const body = {
      user: User.get()
    };
    return this.http.post<TransportData>(url + '/transport/list', body);
  }

  setTransport(companies: string[]) {
    const body = {
      user: User.get(),
      companies: companies
    };
    return this.http.post<TransportData>(url + '/transport/set', body);
  }

}

export class TransportData {
  email: string;
  companies: string[];
  date: Date
}