import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getBindingsEnabled } from '@angular/core/src/render3/state';
import { Observable } from 'rxjs';
import { User } from '../store';
import { url } from '../util';
import { BinTrans } from './bins-dashboard.component';

@Injectable({
  providedIn: 'root'
})
export class BinsService {

  constructor(private http: HttpClient) {
     
  }
  
  getBinsList(date : string) : Observable<BinTrans[]> {
    const route = url + '/bins?date=' + date;
    const body = {
      user: User.get()  
    }
    return this.http.post<BinTrans[]>(route, body);
  }

  getKpins() : Observable<BinTrans[]> {
    const route = url + '/bins';
    const body = {
      user: User.get()  
    }
    return this.http.post<BinTrans[]>(route, body);
  }
  
  addBin(trans : BinTrans) : Observable<BinTrans> {
    const route = url + '/bins/add';
    const body = {
      user : User.get(),
      data : trans
    };
    return this.http.post<BinTrans>(route, body);
  }
}
