import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Record } from '../record/record';
import { User } from '../store';
import { url } from '../util';
import { Audit } from './audit';
import { AuditSpecs } from '../audit-specs/audit-specs';

@Injectable()
export class AuditService {

  constructor(private http: HttpClient) { }

  getAudit(id: string): Observable<Audit> {
    const route = url + '/audits/get';
    const body = {
      user: User.get(),
      id: id
    };
    return this.http.post<Audit>(route, body);
  }

  getAudits(record: Record): Observable<Audit[]> {
    const route = url + '/audits/all';
    const body = {
      user: User.get(),
      id: record._id
    };
    return this.http.post<Audit[]>(route, body);
  }

  getHarvesterAudits(year: string, harvester: string) {
    const route = url + '/audits/harvesters';
    const body = {
      user: User.get(),
      year: year,
      harvester: harvester
    };
    return this.http.post<Audit[]>(route, body);
  }

  newAudit(audit: Audit) {
    const route = url + '/audits/add';
    const body = {
      user: User.get(),
      audit: audit
    };
    return this.http.post<Audit>(route, body);
  }

  deleteAudit(audit: Audit) {
    const route = url + '/audits/remove';
    const body = {
      user: User.get(),
      audit: audit
    }
    return this.http.post<Audit>(route, body);
  }

  updateAudit(audit: Audit) {
    const route = url + '/audits/update';
    const body = {
      user: User.get(),
      audit: audit
    }
    return this.http.post<Audit>(route, body);
  }

  // TODO add crud functions for the audit specs
  newSpec(spec: AuditSpecs) {
    
    const route = url + '/audits/newspec';
    const body = {
      user: User.get(),
      spec: spec
    }
    return this.http.post<AuditSpecs>(route, body);
  }

  getSpecs() {
    const route = url + '/audits/allspecs';
    const body = {
      user: User.get()
    }
    return this.http.post<AuditSpecs[]>(route, body);
  }

  latestSpec() {
    const route = url + '/audits/latestspec';
    const body = {
      user: User.get()
    }
    return this.http.post<AuditSpecs>(route, body);
  }
}


