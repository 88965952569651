import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Record } from '../record/record';
import { RecordService } from '../record/record.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Rec, User, Bins } from '../store';
import * as util from '../util';
import * as moment from 'moment';

@Component({
  selector: 'app-stopwatch',
  templateUrl: './stopwatch.component.html',
  styleUrls: ['./stopwatch.component.css']
})
export class StopwatchComponent implements OnInit {

  record: Record;
  myBins = '0';
  status: string[] = util.status;
  myStatus = '';
  bins: number[];

  constructor(
    private recordService: RecordService,
    public location: Location,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  start() {
    const now = moment();
    this.record.startTime = now.format('HH:mm:ss');
    console.log(this.record);
    this.record.status = this.status[2];
    this.myStatus = this.status[2];
    this.recordService.updateRecord(this.record).subscribe(
      data => {
        Rec.store(this.record);
        console.log(data);
      },
      error => util.reRouteWithError(this.router, error)
    );
  }

  update() {
    const now = moment();
    // update value locally
    this.record.updateTime = now.format('HH:mm:ss');
    // send post to update server
    this.record.binsPicked = this.myBins;
    this.record.status = this.myStatus;
    this.recordService.updateRecord(this.record).subscribe(
      data => {
        Rec.store(this.record);
        this.location.back();
      },
      error => util.reRouteWithError(this.router, error)
    );
  }

  finish(status) {

  }

  started() {
    return this.record.status !== 'Not yet started'
  }

  statusString(stat: string): string {
    return util.statusClass(stat, 'alert');
  }

  pickingRate() {
    const rate = util.pickingRate(this.record);
    if (isNaN(rate)) {
      return '';
    } else {
      return rate.toFixed(1);
    }
  }

  pickingTime() {
    return util.pickingTime(this.record);
  }

  getData() {
    this.recordService.getRecord(this.record._id).subscribe(
      data => {
        if (this.myBins === '0') {
          this.myBins = data[0].binsPicked;
        }
        if (this.myStatus === '') {
          this.myStatus = data[0].status;
        }
        this.record = data[0];
      },
      error => util.reRouteWithError(this.router, error)
    );
  }

  ngOnInit() {
    this.record = Rec.get();
    this.bins = Bins.get();
    this.getData();
  }
}
