export class Record {
    _id?: string;
    date = '';
    orchard = '';
    pickPlan = '';
    variety = 'HW';
    kpin = '';
    maturityArea = '';
    blocks = '';
    packhouse = '';
    contractor = '';
    transport = '';
    binsEstimate = '0';
    comments = '';
    startTime = '';
    updateTime = '';
    binsPicked = '0';
    status = 'Not yet started';
    softFruit? = false;
    users?: string[];
}
