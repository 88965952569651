import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../store';
import { report, url } from '../util';

@Injectable()
export class UsersService {

  constructor(private http: HttpClient) { }

  tokenLogin(user) {
    User.store(user);
    const body = {
      user: user
    };
    //report(body);
    return this.http.post<User>(url + '/login/token', body);    
  }

  passwordLogin(user: User, hash) {
    User.store(user);
    const body = {
      user: user,
      hash: hash
    }
    //report(body)
    return this.http.post<User>(url + '/login/password', body);
  }

  newPassword(user: User, hash: string) {
    User.store(user);
    const body = {
      user: user,
      hash: hash
    }
    return this.http.post<User>(url + '/login/newpassword', body)
  }

  getUserList() {
    const route = url + '/users/all';
    const body = { user: User.get() };
    return this.http.post<User[]>(route, body);
  }

  getRecordsUsers(users: string[]) {
    const route = url + '/record/users';
    const body = {
      user: User.get(),
      data: users
    };
    return this.http.post<User[]>(route, body);
  }

  update(user: User) {
    const route = url + '/users/update';
    const body = {
      user: User.get(),
      data: user
    };
    return this.http.post<User[]>(route, body);
  }

  create(user: User) {
    const route = url + '/users/add';
    const body = {
      user: User.get(),
      data: user,
    };
    return this.http.post<User[]>(route, body);
  }

  delete(user: User) {
    const route = url + '/users/delete';
    const body = {
      user: User.get(),
      data: user
    };
    return this.http.post<User[]>(route, body);
  }

  getToken(user: User) {
    const route = url + '/maps';
    const body = {
      user: User.get(),
      data: user
    };
    return this.http.post(route, body);
  }

  resetPassword(user: User) {
    const route = url + '/users/reset'
    const body = {
      user: User.get(),
      data: user
    }
    return this.http.post(route, body);
  }

}
