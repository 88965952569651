import { Component, OnInit, OnDestroy } from '@angular/core';
import * as util from '../util';
import * as moment from 'moment';
import { Record } from '../record/record';
import { Observable } from 'rxjs';
import { RecordService } from '../record/record.service';
import { UsersService } from '../users/users.service';
import { ImportService } from '../import.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Today, Rec, User } from '../store';

@Component({
    selector: 'app-record-list',
    templateUrl: './record-list.component.html',
    styleUrls: ['./record-list.component.css']
})
export class RecordListComponent implements OnInit, OnDestroy {

    h: string[] = util.headings;
    records: Record[] = [];
    allRecords: Record[] = [];
    action = '';
    area = '';
    day: Today;
    currentReq: any;
    currentDay: String = moment().format('DDDo');

    varieties: string[] = util.varieties;
    totals: string[] = util.totals;
    organic = false;
    allUsers: User[];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private recordService: RecordService,
        private usersService: UsersService,
        private importService: ImportService
    ) {
        this.organic = false;
        this.route.params.subscribe(params => {
            if (this.currentReq) {
                clearInterval(this.currentReq);
            }
            this.action = params.action;
            this.area = params.area;
            Today.store({ date: params.date });
            this.day = Today.get();
            this.getData();
        });
    }

    hasOrganic(): boolean {
        return this.records.filter(this.isOrganic).length !== 0;
    }

    isOrganic(rec: Record): boolean {
        let text = rec.orchard + rec.maturityArea + rec.comments;
        text = text.toLowerCase();
        return text.includes('organic');
    }

    toggleOrganic() {
        if (this.organic) {
            this.records = this.allRecords.slice();
        } else {
            this.records = this.records.filter(this.isOrganic);
        }
        this.organic = !this.organic;
    }

    organicButton() {
        if (this.organic) {
            return 'active';
        } else {
            return '';
        }
    }

    toOrchard(r: Record): void {
        Rec.store(r);
        this.router.navigate(['/pick/' + r._id]);
    }

    newRecord(): void {
        Rec.store(new Record());
        this.router.navigate(['/records/new']);
    }

    pickingRate(r: Record): string {
        const rate = util.pickingRate(r);
        if (isNaN(rate)) {
            return '';
        } else {
            return rate.toFixed(1);
        }
    }

    pickTotal(packhouse: string, kind: string): number {
        let ac;
        if (this.action === 'pick') {
            ac = 'pickPlan';
        } else {
            ac = 'packhouse';
        }
        return this.records.filter(
            rec => rec[ac] === packhouse
            && rec[kind] !== ''
            && rec[kind] !== 'TBC'
        ).map(
            rec => parseInt(rec[kind], 10)
        ).reduce(
            (tot, bins) => tot += bins, 0
        );
    }

    varietyTotals(va: string, kind: string): number {
        return this.records.filter(
            rec => rec.variety === va
            && rec[kind] !== ''
            && rec[kind] !== 'TBC'
        ).map(
            rec => parseInt(rec[kind], 10)
        ).reduce(
            (tot, bins) => tot += bins, 0
        );
    }

    statusString(stat: string): string {
        return util.statusClass(stat, 'table');
    }

    actionStr() {
        if (this.action === 'pick') {
            return 'Pick Plan';
        } else {
            return 'Packhouse';
        }
    }

    changeDay(): void {
        this.organic = false;
        if (this.day.date !== '') {
            clearInterval(this.currentReq);
            Today.store(this.day);
            if (util.managers.includes(User.get().role)) {
                this.router.navigate(
                    ['/records/' + this.day.date + '/' + this.action + '/' + this.area]
                );
            } else {
                this.router.navigate(
                    ['/records/' + this.day.date]
                );
            }
        }
    }

    auditors(rec: Record): string {
        if (rec.users && this.allUsers) {
            let us = '';
            rec.users.forEach(u => {
                const uu = this.allUsers.filter(uuu => uuu._id === u)[0];
                if (uu && uu.role === 'auditor') {
                    us = us + ', ' + uu.email;
                }
            });
            return us.slice(2);
        } else {
            return '';
        }
    }

    harvester(rec: Record): string {
        if (rec.users && this.allUsers) {
            let us = '';
            rec.users.forEach(u => {
                const uu = this.allUsers.filter(uuu => uuu._id === u)[0];
                if (uu && (uu.role === 'contractor' || uu.role === 'self')) {
                    us = us + ', ' + uu.email;
                }
            });
            return us.slice(2);
        } else {
            return '';
        }
    }

    getUsers() {
        const data: string[] = this.records
            .map(
                rec => rec.users
            ).reduce(
                (all, us) => all.concat(us), []
        );
        this.usersService.getRecordsUsers(data)
            .subscribe(thing => {
                this.allUsers = thing;
            },
            error => util.reRouteWithError(this.router, error)
        );
    }

    getData() {
        // decide on which property to filter.
        let prop;
        if (this.action === 'pick') {
            prop = 'pickPlan';
        } else {
            prop = 'packhouse';
        }
        const request = () => {
            const now = moment().format('DDDo');
            if (now !== this.currentDay) {
                // we have past over midnight into the next day
                // jump to the new day
                this.day = new Today();
            }
            this.currentDay = now;
            this.recordService.getRecordsList(this.day.date)
                .subscribe(data => {
                    this.records = [];
                    if (this.area && this.action) {
                        this.records = data.filter(
                            r => r[prop] === this.area
                        );
                    } else {
                        this.records = data;
                    }
                    this.getUsers();
                    this.records.sort(util.statusSort);
                    this.allRecords = this.records;
                    if (this.organic) {
                        this.records = this.records.filter(this.isOrganic);
                    }
                },
                error => util.reRouteWithError(this.router, error)
            );
        };
        request();
        this.currentReq = setInterval(request, 10000);
    }

    addMA() {
        Rec.store(new Record());
        this.router.navigate(['/records/new']);
    }

    manager() {
        return util.managers.includes(User.get().role);
    }

    import(file) {
        // this.importService.importGoogleCSV(file);
    }

    displayDate(date: string) {
        return moment(date, 'YYYY-MM-DD').format('Do MMM YY');
    }

    softFruit(record) {
      return record.softFruit ? '✓' : 'x';
    }

    ngOnInit() {

    }

    ngOnDestroy() {
        clearInterval(this.currentReq);
    }
}
