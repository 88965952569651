import { Component, OnInit } from '@angular/core';
import { AuditSpecs } from './audit-specs';
import { AuditService } from '../audit/audit.service';
import { User } from '../store';
import { Location } from '@angular/common';

@Component({
  selector: 'app-audit',
  templateUrl: './audit-spec.component.html',
  styleUrls: ['./audit-spec.component.css']
})

export class AuditSpecComponent implements OnInit {
  specs: AuditSpecs[] = [];
  percents: Proportions[] = [];
  currentSpec: number = 0;
  showSpecs: string[] = [];
  sure = false;

  constructor(
    private auditService: AuditService,
    public location: Location 
  ) {
    // get the latest audit specs from the server
    this.auditService.getSpecs().subscribe(
      data => {
        this.specs = data;
        console.log(this.specs);
        if (this.specs.length === 0) {
          this.specs.push({
            user: User.get().email,
            date: new Date().toISOString().slice(0, 10),
            categories: []
          });
        }        
        this.currentSpec = this.specs.length - 1;
        for (let i = 0; i < this.specs.length; i++) {
          this.showSpecs.push('Show');
        }
        this.calculatePercents();
      },
      error => {
        console.log(error);
      }
    );
  }

  ngOnInit(): void {}
  
  addCategory() {
    this.specs[this.currentSpec].categories.push({
      name: '',
      specs: [],
      weight: 1
    });
    this.calculatePercents();
  }
  
  removeCategory(index: number) {
    this.specs[this.currentSpec].categories.splice(index, 1);
    this.calculatePercents();
  }

  addSpec(index: number) {
    this.specs[this.currentSpec].categories[index].specs.push({
      name: '',
      weight: 1,
      pass: 0
    });
    this.calculatePercents();
  }

  calculatePercents() {
    console.log('called')
    this.percents = [];
    let catTotal = this.specs[this.currentSpec].categories.reduce((acc, cur) => acc + cur.weight, 0);
    this.specs[this.currentSpec].categories.forEach(c => {
      this.percents.push({
        p: c.weight / catTotal,
        s: []
      });
      let specTotal = c.specs.reduce((acc, cur) => acc + cur.weight, 0);
      c.specs.forEach(s => {
        this.percents[this.percents.length - 1].s.push(s.weight / specTotal);
      });
    });
  }

  removeSpec(categoryIndex: number, specIndex: number) {
    this.specs[this.currentSpec].categories[categoryIndex].specs.splice(specIndex, 1);
    this.calculatePercents();
  }

  move(index: number, up: boolean) {
    const category = this.specs[this.currentSpec].categories[index];
    this.specs[this.currentSpec].categories.splice(index, 1);
    if (up) {
      this.specs[this.currentSpec].categories.splice(index - 1, 0, category);
    } else {
      this.specs[this.currentSpec].categories.splice(index + 1, 0, category);
    }
    this.calculatePercents();
  }

  moveSpec(categoryIndex: number, specIndex: number, up: boolean) {
    const spec = this.specs[this.currentSpec].categories[categoryIndex].specs[specIndex];
    this.specs[this.currentSpec].categories[categoryIndex].specs.splice(specIndex, 1);
    if (up) {
      this.specs[this.currentSpec].categories[categoryIndex].specs.splice(specIndex - 1, 0, spec);
    } else {
      this.specs[this.currentSpec].categories[categoryIndex].specs.splice(specIndex + 1, 0, spec);
    }
    this.calculatePercents(); 
  }

  save() {
    if (!this.sure) {
      this.sure = true;
      return;
    }
    // save the audit specs to the server
    // set the user to the current user
    this.specs[this.currentSpec].user = User.get().email;
    // set the date to today
    this.specs[this.currentSpec].date = new Date().toISOString().slice(0, 10);
    
    this.auditService.newSpec(this.specs[this.currentSpec]).subscribe(
      data => {
        console.log(data);
        // get the latest audit specs from the server
        this.auditService.getSpecs().subscribe(
          data => {
            this.specs = data;        
            this.currentSpec = this.specs.length - 1;
            this.calculatePercents();
            // // move to the top of the page
            // window.scrollTo(0, 0);
            this.location.back();
          },
          error => {
            console.log(error);
          }
        );
      },
      error => {
        console.log(error);
      }
    );
  }

  prev() {
    if (this.currentSpec === 0) {
      return;
    } else {
      this.currentSpec = (this.currentSpec - 1);
      this.calculatePercents();
    }
    
  }

  next() {
    if (this.currentSpec === this.specs.length - 1) {
      return;
    } else {
      this.currentSpec = (this.currentSpec + 1);
      this.calculatePercents();
    }
  }

  toggleSpecs(categoryIndex: number) {
    if (this.showSpecs[categoryIndex] === 'Show') {
      this.showSpecs[categoryIndex] = 'Hide';
    } else {
      this.showSpecs[categoryIndex] = 'Show';
    }
  }

  showClass(categoryIndex: number): string {
    if (this.showSpecs[categoryIndex] === 'Show') {
      return 'btn btn-success';
    } else {
      return 'btn btn-warning';
    }
  }

  sureClass() {
    if (this.sure) {
      return 'btn btn-warning';
    } else {
      return 'btn btn-success';
    }
  }

  sureText() {
    
    if (this.sure) {
      return 'Are you sure?';
    } else {
      return 'Save';
    }
  }
}

class Proportions {
  p: number;
  s: number[];
}