import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BinsAllocations, Today, User } from '../store';
import { UsersService } from '../users/users.service';
import { BinsService } from './bins.service';
import * as util from '../util';
import * as moment from 'moment';

@Component({
  selector: 'app-bins-dashboard',
  templateUrl: './bins-dashboard.component.html',
  styleUrls: ['./bins-dashboard.component.css']
})

export class BinsDashboardComponent implements OnInit {

  day: Today;
  bins: BinTrans[] = [];
  locations: Bin[] = [];
  locs = false;
  trans = false;

  constructor(
    private binService : BinsService,
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    //public location: Location
  ) {
    this.route.params.subscribe(params => {
      Today.store(<Today>params);
      this.day = Today.get();

    });
    this.locations = BinsAllocations.get();
    this.binService.getBinsList(this.day.date).subscribe(
      data => {
        this.bins = data;
        this.makeLocations();
        BinsAllocations.store(this.locations);
      },
      error => util.reRouteWithError(this.router, error)
    );
  }

  makeLocations() {
    let m: Map<string, Bin> = new Map();
    this.bins.forEach(b => {
      // adding bins
      if (b.to !== 'storage') {
        if (m.has(b.to)) {
          m.set(b.to, new Bin(b.to, Number(m.get(b.to).amount) + Number(b.amount)));
        } else {
          m.set(b.to, new Bin(b.to, b.amount));
        }
      }
      // removing bins
      if (m.has(b.from)) {
        m.set(b.from, new Bin(b.from, m.get(b.from).amount - b.amount));
      }
    });
    this.locations = [];
    m.forEach((v, k) => {
      this.locations.push(v);
    });   
  }

  ngOnInit() {
  }

  manager() {
    return util.managers.includes(User.get().role);
  }

  niceDate() {
    return moment(this.day.date).format('dddd Do MMMM');
  }

  onsite(): number {
    return this.inCirculation() - this.totalAllocated();
  }

  returned(): number {
    return this.bins
      .filter(b => b.to === 'site' && b.from !== 'storage')
      .map(b => b.amount).reduce(sum, 0);
  }

  totalAllocated(): number {
    return this.bins
      .filter(b => b.from === 'site')
      .map(b => b.amount).reduce(sum, 0) - this.returned();
  }

  inCirculation(): number{
    return this.bins
      .filter(b => b.from === 'storage')
      .map(b => b.amount)
      .reduce(sum, 0);    
  }

  changeDay(): void {
    if (this.day.date !== '') {
        Today.store(this.day);
        
        this.router.navigate(['/bins/' + this.day.date]);
        this.binService.getBinsList(this.day.date).subscribe(
          data => {
            this.bins = data;
            this.makeLocations();
          },
          error => util.reRouteWithError(this.router, error)
        );
    }
  }

  addBins() {
    BinsAllocations.store([]);
    this.router.navigate(['/bins/' + this.day.date + '/add']);
  }

  newTransaction() {
    BinsAllocations.store(this.locations);
    this.router.navigate(['/bins/' + this.day.date + '/add']);
  }
}

const sum = (acc: number, val: number): number => Number(acc) + Number(val);

export class BinTrans {
  date : string;
  time : string;
  amount : number;
  from : string;
  to : string;
}

export class Bin {
  kpin: string;
  amount : number;

  constructor(k, a) {
    this.kpin = k;
    this.amount = a;
  }
}
