import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Record } from './record';
import { User } from '../store';
import { url } from '../util';

@Injectable()
export class RecordService {

    constructor(private http: HttpClient) { }

    getRecordsList(date): Observable<Record[]> {
        const route = url + '/records?date=' + date;
        const body = {
            user: User.get(),
        };
        return this.http.post<Record[]>(route, body);
    }

    getRecord(id: string): Observable<Record> {
        const route = url + '/records?id=' + id;
        const body = {
            user: User.get(),
        };
        return this.http.post<Record>(route, body);
    }

    updateRecord(record: Record): Observable<Record> {
        const route = url + '/records/update';
        const body = {
            user: User.get(),
            data: record
        };
        return this.http.post<Record>(route, body);
    }

    addRecords(records: Record[]) {
        const route = url + '/records/add';
        const body = {
            user: User.get(),
            data: records
        };
        return this.http.post<Record>(route, body);
    }

    deleteRecord(record: Record) {
        const route = url + '/records/delete';
        const body = {
            user: User.get(),
            data: record
        };
        return this.http.post<Record>(route, body);
    }

    getMap(record: Record) {
        const route = 'https://content.dropboxapi.com/2/files/download';
        try {
            const auth = 'Bearer ' + JSON.parse(localStorage.dropbox_token).token;
            const path = '{\"path\":\"/DMS Current Maturity Maps/' + record.kpin + '.pdf\"}';
            const opts = {
                headers: new HttpHeaders({
                    'Authorization': auth,
                    'Dropbox-API-Arg': path
                }),
                responseType: 'blob' as 'json'
            };
            return this.http.post<Blob>(route, '', opts);
        } catch (e) {
            console.log('No auth for maps');
        }
    }
}
