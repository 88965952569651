import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BinTrans } from '../bins-dashboard/bins-dashboard.component';
import { BinsService } from '../bins-dashboard/bins.service';
import { reRouteWithError } from '../util';

@Component({
  selector: 'app-bins-search',
  templateUrl: './bins-search.component.html',
  styleUrls: ['./bins-search.component.css']
})
export class BinsSearchComponent implements OnInit {

  allKpins: BinTrans[] = [];
  kpins: BinTrans[] = [];
  searchString: string = '';

  constructor(
    private binsService: BinsService,
    private router: Router
  ) {
    this.binsService.getKpins().subscribe(
      data => {
        this.allKpins = data;
        this.kpins = this.allKpins;      
      },
      error => reRouteWithError(this.router, error)
    );
  }

  search() {
    this.kpins = this.allKpins.filter(b => b.to.startsWith(this.searchString));
  }

  ngOnInit() {
  }

}
